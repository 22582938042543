import provideState_MaX9fQr3GV from "/builds/bazinga/pharmasuisse/frontend/node_modules/nuxt-graphql-middleware/dist/runtime/plugins/provideState.js";
import revive_payload_client_4sVQNw7RlN from "/builds/bazinga/pharmasuisse/frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/builds/bazinga/pharmasuisse/frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/builds/bazinga/pharmasuisse/frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/builds/bazinga/pharmasuisse/frontend/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/builds/bazinga/pharmasuisse/frontend/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/builds/bazinga/pharmasuisse/frontend/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/builds/bazinga/pharmasuisse/frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_hOiJmHwM61 from "/builds/bazinga/pharmasuisse/frontend/.nuxt-pharmago/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/builds/bazinga/pharmasuisse/frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import analytics_SVnwK7EMrr from "/builds/bazinga/pharmasuisse/frontend/plugins/analytics.ts";
import trackSwitchPage_client_VPwyr1E3mT from "/builds/bazinga/pharmasuisse/frontend/plugins/trackSwitchPage.client.ts";
import datadog_client_cfAAU11B0P from "/builds/bazinga/pharmasuisse/frontend/plugins/datadog.client.ts";
import blokkliEditable_kzWzvLoIBF from "/builds/bazinga/pharmasuisse/frontend/node_modules/blokkli-beta/dist/runtime/plugins/blokkliEditable.mjs";
import clientCache_SyeCpR1f31 from "/builds/bazinga/pharmasuisse/frontend/plugins/clientCache.ts";
import plugin_vue3_A0OWXRrUgq from "/builds/bazinga/pharmasuisse/frontend/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import graphqlMiddleware_2xI7SMabjg from "/builds/bazinga/pharmasuisse/frontend/plugins/graphqlMiddleware.ts";
import staticNodes_h2gNV3qMId from "/builds/bazinga/pharmasuisse/frontend/node_modules/@bazinga/vuepal/dist/runtime/plugins/staticNodes.mjs";
import drupalUser_HxAWH89yLt from "/builds/bazinga/pharmasuisse/frontend/plugins/drupalUser.ts";
import language_Zy8KLHw9oT from "/builds/bazinga/pharmasuisse/frontend/node_modules/nuxt-language-negotiation/dist/runtime/plugins/language.mjs";
import globalConfig_WKr6vSZGKU from "/builds/bazinga/pharmasuisse/frontend/plugins/globalConfig.ts";
import texts_P4dMAr3Ct0 from "/builds/bazinga/pharmasuisse/frontend/node_modules/nuxt-easy-texts/dist/runtime/plugins/texts.mjs";
export default [
  provideState_MaX9fQr3GV,
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_hOiJmHwM61,
  prefetch_client_5tzzN0oIVL,
  analytics_SVnwK7EMrr,
  trackSwitchPage_client_VPwyr1E3mT,
  datadog_client_cfAAU11B0P,
  blokkliEditable_kzWzvLoIBF,
  clientCache_SyeCpR1f31,
  plugin_vue3_A0OWXRrUgq,
  graphqlMiddleware_2xI7SMabjg,
  staticNodes_h2gNV3qMId,
  drupalUser_HxAWH89yLt,
  language_Zy8KLHw9oT,
  globalConfig_WKr6vSZGKU,
  texts_P4dMAr3Ct0
]