import { defineNuxtPlugin } from '#app'

/**
 * Loads the global config and puts it in the globalConfig store.
 *
 * Only happens during SSR.
 */
export default defineNuxtPlugin({
  name: 'phs-analytics',
  setup(nuxtApp) {
    if (process.client) {
      return
    }
    // Where the dataLayer stuff is stored during SSR. We attach it to the
    // head after the page was rendered.
    const ssrDataLayer = useState<any[]>('ssrDataLayer', () => [])

    nuxtApp.hook('app:rendered', (ctx) => {
      ctx.ssrContext?.head.push({
        script: [
          {
            children: `window.dataLayer = ${JSON.stringify(
              ssrDataLayer.value,
            )}`,
            tagPriority: -100,
          },
        ],
      })
      ssrDataLayer.value = []
    })
  },
})
