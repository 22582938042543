import { datadogLogs } from '@datadog/browser-logs'
import { defineNuxtPlugin } from '#app'

export default defineNuxtPlugin({
  name: 'datadog-init',
  setup({ $config }) {
    const { datadogToken: clientToken, isDev } = $config.public

    if (clientToken && !isDev) {
      datadogLogs.init({
        clientToken,
        site: 'datadoghq.eu',
        forwardErrorsToLogs: true,
        sessionSampleRate: 100,
      })
    }
  },
})
