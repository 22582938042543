import { defineNuxtPlugin } from '#app/nuxt'
import { LazyRokkaImage, LazySpriteSymbol, LazyNuxtPageDependency, LazyRenderCacheable, LazyBlokkliField, LazyBlokkliEditable, LazyBlokkliProvider, LazyBlokkliItem, LazyVuepalLocalTasks, LazyVuepalAdminToolbar } from '#components'
const lazyGlobalComponents = [
  ["RokkaImage", LazyRokkaImage],
["SpriteSymbol", LazySpriteSymbol],
["NuxtPageDependency", LazyNuxtPageDependency],
["RenderCacheable", LazyRenderCacheable],
["BlokkliField", LazyBlokkliField],
["BlokkliEditable", LazyBlokkliEditable],
["BlokkliProvider", LazyBlokkliProvider],
["BlokkliItem", LazyBlokkliItem],
["VuepalLocalTasks", LazyVuepalLocalTasks],
["VuepalAdminToolbar", LazyVuepalAdminToolbar],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
