<template>
  <nav
    class="navbar lg:!flex until-lg:border-y until-lg:border-y-gray-300 xl:mt-40 lg:ml-auto"
    @mouseleave="onNavbarMouseLeave"
  >
    <ul class="navbar-first-list" @click="onClick">
      <li
        v-for="(link, i) in linksMapped"
        :key="link.id"
        class="until-lg:px-20"
      >
        <a
          :href="link.url"
          class="navbar-first-link"
          :class="{
            '!text-green-alt before:scale-100': hover === i || link.active,
            'before:scale-y-0': hover !== i && !link.active,
            'until-lg:text-gray-500': hover >= 0 && hover !== i,
          }"
          @mouseenter="onMouseEnter(i)"
          @mouseleave="onMouseLeave"
          @click.capture.prevent="
            onFirstLevelClick($event, i, !!link.children.length, link.url)
          "
        >
          <span>{{ link.title }}</span>
          <SpriteSymbol
            v-if="link.children.length"
            name="chevron"
            class="-rotate-90 lg:hidden w-10 h-10 text-green-alt"
          />
        </a>
        <NavbarFirstLevel
          v-if="link.children.length && !isIsland"
          :links="link.children"
          :visible="hover === i"
          :title="link.title"
          :has-transition="hasTransition || mobileMenuOpen"
          @back="setActive(-1)"
        />
      </li>
    </ul>

    <ClientOnly>
      <Teleport to="body">
        <TransitionFade>
          <div
            v-show="
              (menuOpen && !hasMobileBehavior) ||
              (mobileMenuOpen && hasMobileBehavior)
            "
            id="layout-overlay"
            class="fixed top-0 left-0 w-full h-full z-40 bg-gray-900/90 lg:bg-gray-700/70"
            @click="onOverlayClick"
            @mouseenter="onOverlayMouseEnter"
            @mouseleave="onOverlayMouseLeave"
          >
            <SpriteSymbol
              v-if="hasMobileBehavior"
              name="arrow"
              class="w-20 h-20 text-white relative top-[82px] left-15 rotate-180 lg:hidden transition-all duration-300 pointer-events-none"
              :class="{
                'translate-x-50 opacity-0 text-green-alt': hover === -1,
              }"
            />
          </div>
        </TransitionFade>
      </Teleport>
    </ClientOnly>
  </nav>
</template>

<script setup lang="ts">
import type { PropType } from 'vue'
import type {
  MainMenuLinkTreeFirstFragment,
  MainMenuLinkTreeFragment,
} from '#graphql-operations'
import type { PageLanguage } from '#language-negotiation/language'

defineOptions({
  name: 'Navbar',
})

export type NavbarLink = {
  id?: string
  url?: string
  title?: string
  children: NavbarLink[]
  active: boolean
  paywall: {
    showPaywall: boolean
    requiredRoles: string[]
  }
}

const router = useRouter()
const route = useRoute()
const hasTransition = ref(false)

const emit = defineEmits(['close'])

const props = defineProps({
  language: {
    type: String as PropType<PageLanguage>,
    default: '',
  },
  links: {
    type: Array as PropType<MainMenuLinkTreeFirstFragment[] | null>,
    default: () => [],
  },
  mobileMenuOpen: {
    type: Boolean,
  },
  hasMobileBehavior: {
    type: Boolean,
  },
  isIsland: {
    type: Boolean,
  },
})

const hover = ref(-1)
const menuOpen = computed(() => {
  return hover.value >= 0
})

let timeout: any = null

function onOverlayMouseEnter() {
  if (props.hasMobileBehavior) {
    return
  }
  clearTimeout(timeout)
  timeout = setTimeout(() => {
    setActive(-1)
  }, 400)
}

function onOverlayMouseLeave() {
  clearTimeout(timeout)
}

/**
 * We only have a single click listener for performance reasons because there's
 * potentially hundreds of menu items.
 */
function onClick(e: MouseEvent) {
  if (e.target && 'href' in e.target && typeof e.target.href === 'string') {
    const target: HTMLAnchorElement = e.target as HTMLAnchorElement
    const href = target.getAttribute('href')
    // Make sure we only route with internal URLs.
    if (href && !href.startsWith('http')) {
      e.preventDefault()
      router.push(href)
      if (props.hasMobileBehavior) {
        emit('close')
        return
      }
      setActive(-1)
    }
  }
}

function setActive(index: number, toggle?: boolean) {
  const hasChildren = !!linksMapped.value[index]?.children.length
  if (!hasChildren) {
    hover.value = -1
    return
  }
  hasTransition.value = index === -1 || hover.value === -1
  // Behavior when toggling is requested.
  if (toggle) {
    hover.value = hover.value === index ? -1 : index
    return
  }
  hover.value = index
}

function onFirstLevelClick(
  e: MouseEvent,
  index: number,
  hasChildren: boolean,
  path?: string,
) {
  if (!hasChildren) {
    if (path) {
      router.push(path)
    }
    emit('close')
    hover.value = -1
    return
  }
  e.stopPropagation()
  e.preventDefault()
  setActive(index, props.hasMobileBehavior)
}

function onMouseEnter(index: number) {
  clearTimeout(timeout)
  if (props.hasMobileBehavior) {
    return
  }
  timeout = setTimeout(() => {
    setActive(index)
  }, 400)
}

function onMouseLeave() {
  if (props.hasMobileBehavior) {
    return
  }
  clearTimeout(timeout)
}

function onOverlayClick() {
  if (props.mobileMenuOpen) {
    if (hover.value !== -1) {
      return setActive(-1)
    }
    return emit('close')
  }

  setActive(-1)
}

function mapLink(
  v: MainMenuLinkTreeFirstFragment | MainMenuLinkTreeFragment,
): NavbarLink {
  const url = v.link.url
  const path = url?.path || ''
  const paywall =
    url && 'entity' in url && url.entity && 'paywall' in url.entity
      ? url.entity.paywall
      : undefined
  return {
    id: v.link.content?.uuid,
    url: v.link.url?.path,
    title: v.link.label,
    active: route.path.startsWith(path),
    paywall: {
      showPaywall: !!paywall?.showPaywall,
      requiredRoles: paywall?.requiredRoles || [],
    },
    children:
      v && 'subtree' in v && v.subtree.length ? v.subtree.map(mapLink) : [],
  }
}

const linksMapped = computed<NavbarLink[]>(() => {
  if (!props.links) {
    return []
  }
  return props.links
    .filter((item) => item.link?.content?.parent === null)
    .map(mapLink)
})

function onNavbarMouseLeave() {
  if (props.hasMobileBehavior) {
    return
  }
  hover.value = -1
}
</script>

<style lang="postcss">
/* Don't put any CSS in here! Put it in components/LayoutHeader/index.vue instead. */
</style>
