import { defineNuxtPlugin } from 'nuxt/app'
import { FetchResponse } from 'ofetch'
import { getLanguageFromPath } from '~/helpers/language'
import { useNotifications } from '~/stores/notifications'

interface MessengerMessage {
  type: 'status' | 'warning' | 'error'
  message: string
}

interface GraphqlResponseWithMessage {
  data: {
    messengerMessages?: undefined | MessengerMessage[]
  }
}

/**
 * Try to extract the messages from a GraphQL query or mutation.
 */
function extractMessages(
  response: FetchResponse<GraphqlResponseWithMessage>,
): MessengerMessage[] {
  if (
    response._data &&
    typeof response._data === 'object' &&
    response._data.data?.messengerMessages
  ) {
    return response._data.data.messengerMessages
  }

  return []
}

/**
 * This is only called when performing a query or mutation from within the nuxt
 * app (e.g. not via custom server routes).
 */
export default defineNuxtPlugin({
  name: 'phs-graphql-fetch-options',
  setup(app) {
    const state = useGraphqlState(app)
    const notify = useNotifications()
    const route = useRoute()

    state.fetchOptions = {
      onResponse(result: any) {
        try {
          extractMessages(result.response).forEach((v) => {
            // Set response uncacheable when there's messages.
            useRouteCache((c) => c.setUncacheable(), app.ssrContext?.event)
            if (v.type === 'error') {
              notify.error(v.message)
            } else {
              notify.success(v.message)
            }
          })
          if (result.response._data.data.__cacheability) {
            const cacheability = result.response._data.data.__cacheability
            if (cacheability.isCacheable) {
              useRouteCache(
                (c) => c.addTags(cacheability.tags).setCacheable(),
                app.ssrContext?.event,
              )
            } else {
              useRouteCache((c) => c.setUncacheable(), app.ssrContext?.event)
            }
            delete result.response._data.data.__cacheability
            delete result.response._data.data.messengerMessages
          }
        } catch (_e) {
          useRouteCache((c) => c.setUncacheable(), app.ssrContext?.event)
          // Something went wrong. Make the route uncacheable to prevent caching errors.
        }
      },
      onRequest({ options }: any) {
        // @TODO: Find out why this could potentially lead to an infinite
        // redirect loop during SSR.
        try {
          if (!options.params) {
            options.params = {}
          }
          // Workaround until plugin order is fixed.
          const path = route.path
          const language = getLanguageFromPath(path)

          options.params.__language_context = language
          options.params.__server = process.server || undefined
          options.params.__client = process.client || undefined

          // @TODO: Use a build hash calculated at runtime for
          // cache busting.
          // options.params.t = Date.now()

          if (!options.headers) {
            options.headers = {}
          }
          options.headers = useRequestHeaders()

          return options
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error('Exception in onRequest handler:', e)
        }
      },
    }
  },
})
