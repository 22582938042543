import { default as indexkoivs8q9ZbMeta } from "/builds/bazinga/pharmasuisse/frontend/pages/[...slug]/index.vue?macro=true";
import { default as indexfKa4cA2aWoMeta } from "/builds/bazinga/pharmasuisse/frontend/pages/article/[...slug]/index.vue?macro=true";
import { default as indexk89KPbqs69Meta } from "/builds/bazinga/pharmasuisse/frontend/pages/event/[nid]/[...slug]/index.vue?macro=true";
import { default as _91id_93IWwYrHPVI3Meta } from "/builds/bazinga/pharmasuisse/frontend/pages/form/[id].vue?macro=true";
import { default as _91id_93XVGWHcUqiOMeta } from "/builds/bazinga/pharmasuisse/frontend/pages/form/confirmation/[id].vue?macro=true";
import { default as indexsnyXHIn6tbMeta } from "/builds/bazinga/pharmasuisse/frontend/sites/pharmago/pages/index.vue?macro=true";
import { default as islands_45pageHIB3DRLxLuMeta } from "/builds/bazinga/pharmasuisse/frontend/pages/islands-page.vue?macro=true";
import { default as indexpzCPJ6kWkIMeta } from "/builds/bazinga/pharmasuisse/frontend/pages/media/[id]/index.vue?macro=true";
import { default as index22YwN5CyGpMeta } from "/builds/bazinga/pharmasuisse/frontend/sites/pharmago/pages/navigator-debug/index.vue?macro=true";
import { default as index2NYjB9fafiMeta } from "/builds/bazinga/pharmasuisse/frontend/sites/pharmago/pages/navigator/index.vue?macro=true";
import { default as indexYVga7PhUChMeta } from "/builds/bazinga/pharmasuisse/frontend/pages/node/[nid]/index.vue?macro=true";
import { default as indexcdn7sLSV3BMeta } from "/builds/bazinga/pharmasuisse/frontend/sites/pharmago/pages/pharmacy/[id]/index.vue?macro=true";
import { default as index9tPdmztHdEMeta } from "/builds/bazinga/pharmasuisse/frontend/pages/press-release/[...slug]/index.vue?macro=true";
import { default as _91paragraphUuid_93YGhs5pMuRUMeta } from "/builds/bazinga/pharmasuisse/frontend/pages/print/paragraph/[nid]/[paragraphUuid].vue?macro=true";
import { default as indexlIvEVVgGTbMeta } from "/builds/bazinga/pharmasuisse/frontend/pages/search/index.vue?macro=true";
import { default as component_45stub86C2VB3VVRMeta } from "/builds/bazinga/pharmasuisse/frontend/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stub86C2VB3VVR } from "/builds/bazinga/pharmasuisse/frontend/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "drupal-route",
    path: "/:slug(.*)*",
    meta: indexkoivs8q9ZbMeta || {},
    component: () => import("/builds/bazinga/pharmasuisse/frontend/pages/[...slug]/index.vue")
  },
  {
    name: "article-slug",
    path: "/artikel/:slug(.*)*",
    meta: indexfKa4cA2aWoMeta || {},
    component: () => import("/builds/bazinga/pharmasuisse/frontend/pages/article/[...slug]/index.vue")
  },
  {
    name: "event-nid-slug",
    path: "/event/:nid/:slug(.*)*",
    meta: indexk89KPbqs69Meta || {},
    component: () => import("/builds/bazinga/pharmasuisse/frontend/pages/event/[nid]/[...slug]/index.vue")
  },
  {
    name: "webform",
    path: "/form/:id()",
    meta: _91id_93IWwYrHPVI3Meta || {},
    component: () => import("/builds/bazinga/pharmasuisse/frontend/pages/form/[id].vue")
  },
  {
    name: "webform-confirmation",
    path: "/form/confirmation/:id()",
    meta: _91id_93XVGWHcUqiOMeta || {},
    component: () => import("/builds/bazinga/pharmasuisse/frontend/pages/form/confirmation/[id].vue")
  },
  {
    name: "home",
    path: "/",
    meta: indexsnyXHIn6tbMeta || {},
    component: () => import("/builds/bazinga/pharmasuisse/frontend/sites/pharmago/pages/index.vue")
  },
  {
    name: "islands-page",
    path: "/islands-page",
    meta: islands_45pageHIB3DRLxLuMeta || {},
    component: () => import("/builds/bazinga/pharmasuisse/frontend/pages/islands-page.vue")
  },
  {
    name: "media-id",
    path: "/media/:id()",
    component: () => import("/builds/bazinga/pharmasuisse/frontend/pages/media/[id]/index.vue")
  },
  {
    name: "navigator-debug",
    path: "/navigator-debug",
    meta: index22YwN5CyGpMeta || {},
    component: () => import("/builds/bazinga/pharmasuisse/frontend/sites/pharmago/pages/navigator-debug/index.vue")
  },
  {
    name: "navigator",
    path: "/navigator",
    meta: index2NYjB9fafiMeta || {},
    component: () => import("/builds/bazinga/pharmasuisse/frontend/sites/pharmago/pages/navigator/index.vue")
  },
  {
    name: "node-canonical",
    path: "/node/:nid()",
    meta: indexYVga7PhUChMeta || {},
    component: () => import("/builds/bazinga/pharmasuisse/frontend/pages/node/[nid]/index.vue")
  },
  {
    name: "pharmacy",
    path: "/:pharmacyId(\\d+)/:slug(.*)*",
    meta: indexcdn7sLSV3BMeta || {},
    component: () => import("/builds/bazinga/pharmasuisse/frontend/sites/pharmago/pages/pharmacy/[id]/index.vue")
  },
  {
    name: "press-release-slug",
    path: "/medienmitteilung/:slug(.*)*",
    meta: index9tPdmztHdEMeta || {},
    component: () => import("/builds/bazinga/pharmasuisse/frontend/pages/press-release/[...slug]/index.vue")
  },
  {
    name: "print-paragraph",
    path: "/print/paragraph/:nid()/:paragraphUuid()",
    meta: _91paragraphUuid_93YGhs5pMuRUMeta || {},
    component: () => import("/builds/bazinga/pharmasuisse/frontend/pages/print/paragraph/[nid]/[paragraphUuid].vue")
  },
  {
    name: "search",
    path: "/search",
    meta: indexlIvEVVgGTbMeta || {},
    component: () => import("/builds/bazinga/pharmasuisse/frontend/pages/search/index.vue")
  },
  {
    name: component_45stub86C2VB3VVRMeta?.name,
    path: "/cart",
    component: component_45stub86C2VB3VVR
  },
  {
    name: component_45stub86C2VB3VVRMeta?.name,
    path: "/user/login",
    component: component_45stub86C2VB3VVR
  }
]