import { defineStore } from 'pinia'

export enum NotificationType {
  Notice,
  Warning,
  Error,
  Success,
}

export type Notification = {
  type: NotificationType
  message: string
  duration: number
  id: string
}

interface State {
  notifications: Notification[]
}

export const useNotifications = defineStore({
  id: 'notifications',
  state: (): State => {
    return {
      notifications: [],
    }
  },
  actions: {
    addNotification(type: NotificationType, message: string) {
      this.notifications.push({
        type,
        message,
        duration: 5000,
        id: Math.round(Math.random() * 1000000000).toString(),
      })
    },
    notice(message: string) {
      this.addNotification(NotificationType.Notice, message)
    },
    error(message: string) {
      this.addNotification(NotificationType.Error, message)
    },
    success(message: string) {
      this.addNotification(NotificationType.Success, message)
    },
    remove(id: string) {
      this.notifications = this.notifications.filter((v) => v.id !== id)
    },
  },
  getters: {},
})
