<template>
  <div class="container mx-auto px-20 until-lg:max-w-[initial]">
    <div class="layout-header">
      <div class="layout-header-logo">
        <nuxt-link
          :to="{ name: 'home' }"
          class="inline-block outline-green-light"
        >
          <Logo :class="{ 'hidden lg:block': hasReducedMobileLogo }" />
          <SpriteSymbol
            v-if="hasReducedMobileLogo"
            name="pharmasuisse-filled"
            class="text-green w-30 h-30 lg:hidden"
          />
        </nuxt-link>
      </div>
      <button class="lg:hidden focus:outline-none" @click="toggleMobileMenu">
        <NavbarBurger
          id="layout-header-burger"
          :active="menuOpen"
          class="lg:hidden"
        />
      </button>

      <transition name="header-slide">
        <div
          v-show="menuOpen || !hasMobileBehavior"
          id="layout-header-wrapper"
          class="layout-header-main-wrapper"
          :class="{ 'is-loaded': isLoaded }"
        >
          <div id="layout-header-main" ref="main" class="layout-header-main">
            <Navbar
              :language="language"
              :links="menuLinks"
              :mobile-menu-open="menuOpen"
              :has-mobile-behavior="hasMobileBehavior"
              :is-island="isIsland"
              @close="toggleMobileMenu"
            />
            <MetaNav :language="language" :is-island="isIsland" />
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useWindowSize } from '@vueuse/core'
import type { PageLanguage } from '#language-negotiation/language'
import { MainMenuLinkTreeFirstFragment } from '#graphql-operations'
defineOptions({
  name: 'LayoutHeader',
})

const { name: siteName } = useCurrentSite()
const { width } = useWindowSize()
const route = useRoute()
const isLoaded = ref(false)

const hasReducedMobileLogo = computed(() => siteName !== 'fphch')

const hasMobileBehavior = computed(() => {
  return width.value <= 1023
})

const menuOpen = ref(false)
const main = ref<HTMLDivElement | null>(null)

provide('mobileMenuOpen', menuOpen)

function toggleMobileMenu() {
  menuOpen.value = !menuOpen.value
  if (menuOpen.value) {
    document.documentElement.classList.add('mobile-menu-open')
  } else {
    document.documentElement.classList.remove('mobile-menu-open')
  }
}

watch(
  () => route.path,
  () => {
    if (menuOpen.value) {
      toggleMobileMenu()
    }
  },
)

defineProps<{
  language: PageLanguage
  menuLinks?: MainMenuLinkTreeFirstFragment[] | null
  isIsland?: boolean
}>()

onMounted(() => {
  isLoaded.value = true
})
</script>

<style lang="postcss">
@import '~/assets/css/partials/layout-header.css';

html.mobile-menu-open {
  @apply until-lg:overflow-hidden;
}
</style>
