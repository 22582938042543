<template>
  <div class="bk-main-canvas">
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
const runtimeConfig = useRuntimeConfig()
const language = useCurrentLanguage()

useHead({
  htmlAttrs: {
    lang: language.value,
  },
  titleTemplate: (titleChunk) => {
    const suffix = runtimeConfig.public.titleSuffix || ''
    return titleChunk ? `${titleChunk} | ${suffix}` : suffix
  },
})
</script>

<style lang="postcss">
.page-content {
  min-height: calc(100vh - var(--layout-header-height) - 5rem);
}

@screen lg {
  .page-slide-enter-active,
  .page-slide-leave-active {
    transition: all 0.4s;
    position: absolute;
    width: 100%;
  }
  .page-slide-enter-from,
  .page-slide-leave-to {
    opacity: 0;
    transform: translateY(40%);
  }

  .page-fade-enter-active,
  .page-fade-leave-active {
    transition: all 0.3s;
    width: 100%;
  }
  .page-fade-enter-from,
  .page-fade-leave-to {
    opacity: 0;
  }

  .app-bottom-enter-active,
  .app-bottom-leave-active {
    transition: all 0.4s;
  }
  .app-bottom-enter-from,
  .app-bottom-leave-to {
    opacity: 0;
    transform: translateY(40%);
  }
}

.bk-body #nuxt-root .bk-main-canvas {
  @apply bg-gray-50;
}

.pb-paragraphs-container .pb-list-item {
  @apply col-span-full col-start-1;
}

.vuepal-admin-toolbar {
  z-index: 9999999999999999;
}
</style>
