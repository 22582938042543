import { staticNodeKeys } from '#vuepal/static-node-keys'
import type {
  GlobalConfigFragment,
  MainMenuLinkTreeFirstFragment,
  ShopPriceLogicFragment,
  StaticNodeFragment,
} from '#graphql-operations'

export type InitData = {
  fetched: boolean
  navbarLinks: MainMenuLinkTreeFirstFragment[]
  globalConfig?: GlobalConfigFragment
  shopPriceLogic: ShopPriceLogicFragment[]
  translations: Record<string, string | [string, string]>
  staticNodes: StaticNodeFragment[]
}

export default async function (): Promise<Ref<InitData>> {
  const currentLanguage = useCurrentLanguage()

  const data = useState<InitData>('initData', () => {
    return {
      fetched: false,
      navbarLinks: [],
      globalConfig: {},
      shopPriceLogic: [],
      translations: {},
      staticNodes: [],
    }
  })
  if (data.value.fetched) {
    return data
  }

  async function loadInitData() {
    const loaded = await useGraphqlQuery('initData', {
      keys: staticNodeKeys,
    }).then((v) => v.data)
    data.value.fetched = true
    if (loaded.navbar?.__typename === 'Menu') {
      data.value.navbarLinks = loaded.navbar.links
    }
    data.value.globalConfig = loaded.globalConfig
    data.value.shopPriceLogic = loaded.shopPriceLogic || []
    data.value.staticNodes = loaded.staticNodes || []
    data.value.translations = Object.entries(
      (loaded.translations || {}) as Record<
        string,
        string | { singular?: string; plural?: string }
      >,
    ).reduce<Record<string, string | [string, string]>>(
      (acc, [fullKey, value]) => {
        const keyWithDots = fullKey.replace('__', '.')
        if (typeof value === 'string') {
          acc[keyWithDots] = value
        } else if (
          typeof value === 'object' &&
          value.plural &&
          value.singular
        ) {
          acc[keyWithDots] = [value.singular, value.plural]
        }
        return acc
      },
      {},
    )
  }

  await loadInitData()

  if (process.client) {
    watch(currentLanguage, () => {
      loadInitData()
    })
  }

  return data
}
