import { defineVuepalAdapter } from '#vuepal/types'
import { useCurrentLanguage, useInitData } from '#imports'

export default defineVuepalAdapter(() => {
  return {
    getTranslations() {
      return Promise.resolve({})
    },
    getStaticNodes() {
      return useInitData().then((v) => v.value.staticNodes)
    },
    getAdminMenu() {
      return useCachedGraphqlQuery('adminToolbar').then((v) => v.data)
    },
    getLocalTasks(path: string) {
      return useCachedGraphqlQuery('localTasks', { path }).then((v) => {
        if (v.data.route && 'localTasks' in v.data.route) {
          return v.data.route.localTasks
        }
        return []
      })
    },
    getCurrentLanguage() {
      return useCurrentLanguage()
    },
  }
})
