
/**
 * Keys of all generated SVG sprite symbols.
 */
export type NuxtSvgSpriteSymbol =
  | 'image-enlarge'
  | 'infoletter'
  | 'people'
  | 'arrow'
  | 'breaking-news'
  | 'broken-flasks'
  | 'calendar'
  | 'cart'
  | 'check'
  | 'checklist'
  | 'chevron'
  | 'clock'
  | 'close'
  | 'delete'
  | 'document'
  | 'email'
  | 'emergency'
  | 'external'
  | 'facebook'
  | 'filter'
  | 'finder'
  | 'individual_member'
  | 'instagram'
  | 'linkedin'
  | 'locator'
  | 'lock'
  | 'mail-secure'
  | 'marker-outline'
  | 'needle'
  | 'pdf'
  | 'pharmasuisse-filled'
  | 'pharmasuisse'
  | 'phone'
  | 'pin'
  | 'play'
  | 'question'
  | 'search'
  | 'special-info'
  | 'tiktok'
  | 'twitter'
  | 'unlocked'
  | 'youtube'

export const SPRITE_PATHS = {"default":"/_nuxt/sprite-default.D5eW044sCk.svg"}
