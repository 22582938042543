<template>
  <svg :alt="alt" class="media-icon" fill="currentColor" :viewBox="viewBox">
    <use :xlink:href="url" :width="width" :height="height"></use>
  </svg>
</template>

<script lang="ts" setup>
import { MediaIconFragment } from '#graphql-operations'
import { slugify } from '~/helpers/slugify'

defineOptions({
  name: 'MediaIcon',
})

const props = defineProps<{
  id?: string
  changed?: MediaIconFragment['changed']
  svg?: MediaIconFragment['svg']

  /**
   * Whether the icon should always be loaded from the pharmasuisse site.
   */
  forcePharmasuisse?: boolean
}>()

const alt = computed(() => props.svg?.first?.alt || '')
const width = computed(() => props.svg?.first?.width?.toString() || '24')
const height = computed(() => props.svg?.first?.height?.toString() || '24')
const viewBox = computed(() => `0 0 ${width.value} ${height.value}`)

const url = computed(() => {
  const changed = props.changed?.first?.formatted || ''
  return `/api/icon/${props.id}--${slugify(
    alt.value,
  )}.svg?h=${changed}&forcePharmasuisse=${props.forcePharmasuisse}#icon`
})

useRouteCache((c) => c.addTags(['M:' + props.id]))
</script>
