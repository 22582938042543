<template>
  <header
    ref="el"
    class="bg-white relative z-50"
    :class="{ 'shadow-lg': hasShadow }"
  >
    <!-- <RenderCacheable :cache-key="language" :max-age="600"> -->
    <LayoutHeader
      :language="language"
      :menu-links="menuLinks"
      :is-island="isIsland"
    />

    <!-- </RenderCacheable> -->
  </header>
</template>

<script lang="ts" setup>
import { useIntersectionObserver } from '@vueuse/core'

const props = defineProps<{
  isIsland?: boolean
}>()

const language = useCurrentLanguage()
const el = ref<HTMLDivElement | null>(null)
const route = useRoute()
const data = await useInitData()

const hasShadow = computed(() => {
  return !route.meta.noHeaderShadow && !props.isIsland
})

const headerVisible = ref(true)
const instance = getCurrentInstance()

const { stop } = useIntersectionObserver(
  el,
  ([{ isIntersecting }]) => {
    headerVisible.value = isIntersecting
    instance?.emit('header-visibility-changed', isIntersecting) // Emit the value
  },
  {
    rootMargin: '100px',
  },
)

onBeforeUnmount(() => {
  stop()
})

const menuLinks = computed(() => data.value.navbarLinks || [])
</script>
