export default defineNuxtPlugin({
  name: 'phs-track-switch-page',
  setup(nuxtApp) {
    const router = useRouter()
    const isInitial = useState<boolean>('_gtm_initial_swtich_page', () => true)
    const { trackSwitchPage } = useAnalyticsTracking()

    // The event is emitted by useDrupalRoute().
    nuxtApp.hook('drupal-route', (ctx) => {
      // This was explicitly desired by analytics: The event should *not*
      // trigger on initial page load.
      if (isInitial.value) {
        isInitial.value = false
        return
      }
      if (!ctx.drupalRoute) {
        return
      }

      trackSwitchPage(
        router.currentRoute.value,
        router.options.history.state.back?.toString(),
        {
          pageTitle: ctx.metatags.title || ('' as any),
          entityType: ctx.drupalRoute.entityType || '',
          entityBundle: ctx.drupalRoute.entityBundle || '',
          entityId: ctx.drupalRoute.entityId || '',
        },
      )
    })

    router.afterEach((to, from) => {
      if (to.meta.drupalRoute) {
        return
      }
      setTimeout(() => {
        trackSwitchPage(to, from.fullPath, {
          pageTitle: document.title,
        })
      }, 500)
    })
  },
})
