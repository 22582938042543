import { Langcode } from '#graphql-operations'

/**
 * Return the current langcode enum for GraphQL requests.
 */
export default function (): ComputedRef<Langcode> {
  const language = useCurrentLanguage()

  return computed(() => {
    if (language.value === 'fr') {
      return Langcode.FR
    } else if (language.value === 'it') {
      return Langcode.IT
    }

    return Langcode.DE
  })
}
