import { defineNuxtPlugin } from '#app'
import { useDrupalUser } from '~/stores/drupalUser'

/**
 * Loads data about the current session.
 */
export default defineNuxtPlugin({
  name: 'phs-drupal-user',
  async setup(nuxtApp) {
    const drupalUser = useDrupalUser(nuxtApp.$pinia as any)
    const langcodeEnum = useCurrentLangcodeEnum()

    // In the rare case that the store failed to load.
    if (!drupalUser) {
      useRouteCache((c) => c.setUncacheable())
      return
    }

    // If the config has already been fetched, return.
    // This is the case when we're client-side, because the config is already
    // shipped via payload from the server.
    if (drupalUser?.fetched) {
      if (drupalUser.isLoggedIn) {
        useRouteCache((c) => c.setUncacheable())
      }
      return
    }

    const headers = useRequestHeaders()
    const hasSessionCookie = (headers.cookie || '').includes('SSESS')

    // Only query the backend for the user if we have a session cookie.
    if (hasSessionCookie || process.client) {
      // Load the information about the Drupal user.
      const { data } = await useGraphqlQuery('drupalUser', {
        language: langcodeEnum.value,
      })
      if (data && data.currentDrupalUser) {
        drupalUser.set(data.currentDrupalUser)
        drupalUser.setIsMasquerading(data.masqueradeContext?.isMasquerading)
      }
    } else {
      drupalUser.setFetched()
    }

    // On the client side, update the user login history.
    if (process.client) {
      drupalUser.initUserNameHistory()
    }

    // Set the page to be uncacheable.
    if (drupalUser.isLoggedIn) {
      useRouteCache((c) => c.setUncacheable())
    }
  },
})
