<template>
  <div class="relative logo-wrapper" :class="'is-site-' + name">
    <img :src="logoPath" alt="Logo" class="logo" />
  </div>
</template>

<script setup lang="ts">
const language = useCurrentLanguage()
const { name } = useCurrentSite()

const logoPath = computed(() => {
  return `/logo-${language.value}.svg`
})
</script>

<style lang="postcss"></style>
