<template>
  <div id="nuxt-error-page">
    <!-- This will capture any errors that might happen when rendering the header. -->
    <!-- We don't want to trigger another error on the error page. -->
    <ClientOnly>
      <NuxtErrorBoundary>
        <AppTop />
      </NuxtErrorBoundary>
    </ClientOnly>
    <div class="page-content pb-60 lg:pb-70 xl:pb-90">
      <Container>
        <ContentHeader :title="title" :title-light="titleLight" :lead="text">
          <template #icon>
            <SpriteSymbol :name="icon" class="is-text-sized" />
          </template>
        </ContentHeader>
        <div class="flex flex-wrap gap-20">
          <a v-if="goToHome" :href="frontUrl" class="button is-primary">{{
            goToHome
          }}</a>
          <nuxt-link
            v-if="goToSearch"
            :to="{ name: 'search' }"
            class="button is-primary"
          >
            {{ goToSearch }}
          </nuxt-link>
        </div>
      </Container>
    </div>
  </div>
</template>

<script setup lang="ts">
/**
 * IMPORTANT: Do not use $texts here because the $texts plugin might not be
 * available because the plugin didn't initialize, which would again cause
 * an error.
 */

interface ErrorMessages {
  accessDeniedTitle: string
  accessDeniedText: string
  pageNotFoundTitle: string
  pageNotFoundText: string
  serverErrorTitle: string
  goToHome: string
  goToSearch: string
  errorText: string
}

const ERRORS: Record<string, ErrorMessages> = {
  de: {
    accessDeniedTitle: 'Zugriff verweigert',
    accessDeniedText: 'Sie haben keinen Zugriff auf diese Seite.',
    pageNotFoundTitle: 'Seite nicht gefunden',
    pageNotFoundText:
      'Die von Ihnen angeforderte Seite konnte nicht gefunden werden. Benutzen Sie unsere Suchfunktion um den gewünschten Inhalt zu finden.',
    serverErrorTitle: 'Seite nicht verfügbar',
    goToHome: 'Zur Startseite',
    goToSearch: 'Seite durchsuchen',
    errorText: 'Fehler',
  },
  fr: {
    accessDeniedTitle: 'Accès refusé',
    accessDeniedText: "Vous n'avez pas accès à cette page.",
    pageNotFoundTitle: 'Page introuvable',
    pageNotFoundText:
      "La page que vous avez demandée n'a pas pu être trouvée. Utilisez notre fonction de recherche pour trouver le contenu que vous recherchez.",
    serverErrorTitle: 'Page non disponible',
    goToHome: 'Aller à la page d’accueil',
    goToSearch: 'Page de recherche',
    errorText: 'Erreur',
  },
  it: {
    accessDeniedTitle: 'Accesso negato',
    accessDeniedText: 'Non hai accesso a questa pagina.',
    pageNotFoundTitle: 'Pagina non trovata',
    pageNotFoundText:
      'La pagina che hai richiesto non è stata trovata. Usa la nostra funzione di ricerca per trovare il contenuto desiderato.',
    serverErrorTitle: 'Pagina non disponibile',
    goToHome: 'Vai alla homepage',
    goToSearch: 'Pagina di ricerca',
    errorText: 'Errore',
  },
}

type ErrorObject = {
  url?: string
  statusCode?: number
  statusMessage?: string
  message?: string
  stack?: string
}

const currentLanguage = useCurrentLanguage()
const language = computed<string>(() => {
  const v = currentLanguage.value
  if (v) {
    if (typeof v === 'string') {
      return v
    }
  }

  return 'de'
})

const texts = computed<ErrorMessages>(() => {
  if (language.value && ERRORS[language.value]) {
    return ERRORS[language.value]
  }
  return ERRORS.de
})

const props = defineProps<{
  error: ErrorObject
}>()

const icon = computed(() => {
  if (props.error?.statusCode === 403) {
    return 'lock'
  }
  return 'broken-flasks'
})

const title = computed(() => {
  if (props.error?.statusMessage) {
    return props.error.statusMessage
  }
  if (props.error?.statusCode === 403) {
    return texts.value.accessDeniedTitle
  } else if (props.error?.statusCode === 404) {
    return texts.value.pageNotFoundTitle
  }

  return texts.value.serverErrorTitle
})

const titleLight = computed(() => {
  if (props.error?.statusCode) {
    return `${texts.value.errorText} ${props.error.statusCode}`
  }
  return texts.value.errorText
})

const text = computed(() => {
  if (props.error?.statusCode === 403) {
    return texts.value.accessDeniedText
  } else if (props.error?.statusCode === 404) {
    return texts.value.pageNotFoundText
  }

  return props.error?.message
})

const goToHome = computed(() => {
  return texts.value.goToHome
})

const goToSearch = computed(() => {
  return texts.value.goToSearch
})

const frontUrl = computed(() => {
  return '/' + language.value
})

onMounted(() => {
  clearError()
})
</script>
