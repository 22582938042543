import { defineNuxtPlugin } from '#app'
import type { Pinia } from 'pinia'
import { useGlobalConfig } from '~/stores/globalConfig'

/**
 * Loads the global config and puts it in the globalConfig store.
 *
 * Only happens during SSR.
 */
export default defineNuxtPlugin({
  name: 'phs-global-config',
  async setup(nuxtApp) {
    const globalConfig = useGlobalConfig(nuxtApp.$pinia as Pinia)
    if (!globalConfig) {
      return
    }

    // If the config has already been fetched, return.
    // This is the case when we're client-side, because the config is already
    // shipped via payload from the server.
    if (globalConfig?.fetched) {
      return
    }

    const initData = await useInitData()

    globalConfig.setConfig(
      initData.value.globalConfig,
      initData.value.shopPriceLogic,
    )
  },
})
